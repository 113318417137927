export const API_BASE_URL = "/api/v1";
export const API_ENDPOINTS = {
  // GST: "emp-configuration-service/gst/detail?gstn",
  GEN_OTP: "/genOtp",
  VERIFY_OTP: "/verifyOtp",
  LOGIN: "/auth/login",
  LOGOUT: "/auth/logout",
  SEARCH_SALON: "/salons",
  REGISTER_HD: "/auth/register",
  CONSULTATION: "/consultation",
  HD_UPDATE: "/hdUpdate",
  CONCERNS: "/concerns",
  RECOMMENDATIONS: "/recommendation",
  UPDATE_CONSULTATION: "/updateConsultation",
  DEACTIVATE_HD: "/hdDeactivate",
  INACTIVATE_CONSULTATION: "inactivateConsultation",
};
