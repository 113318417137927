import Dexie from "dexie";

const DB = new Dexie("biolage-app-1");

DB.version(5).stores({
  hdDetails:
    "hd_id, hd_name, mobile_num, salon_location, salon_city, salon_name, consultations_count",
  consultations:
    "consultation_id, customer_name, beforeImage, afterImage, is_active, created_at, type, customer_id, selectedConcerns, hd_id, hd_mobile_num",
  concernsList: "++id, concerns",
});

DB.close();
export default DB;
