import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  consultation_id: "",
  created_at: "",
  customer_name: "",
  customer_email: "",
  beforeImage: "",
  afterImage: "",
  is_active: false,
  status: "",
  selectedConcerns: {},
  recommendations: {},
  actual_service_code: null,
  actual_product_code: null,
};

export const consultationSlice = createSlice({
  name: "consultation",
  initialState,
  reducers: {
    addConsultation: (state, action) => {
      state.consultation_id = action.payload.consultation_id ?? "";
      state.customer_name = action.payload.customer_name ?? "";
      state.created_at = action.payload.created_at ?? "";
      state.customer_email = action.payload.customer_email ?? "";
      state.beforeImage = action.payload.beforeImage ?? "";
      state.afterImage = action.payload.afterImage ?? "";
      state.is_active = action.payload.is_active ?? "";
      state.status = action.payload.status ?? "";
      state.selectedConcerns = action.payload.selectedConcerns ?? "";
      state.recommendations = action.payload.recommendations ?? "";
      state.actual_service_code = action.payload.actual_service_code ?? "";
      state.actual_product_code = action.payload.actual_product_code ?? "";
    },
    clearConsultation: (state) => {
      Object.assign(state, { ...state, ...initialState });
    },
    addBeforeImage: (state, action) => {
      state.beforeImage = action.payload;
    },
    addAfterImage: (state, action) => {
      state.afterImage = action.payload;
    },
    setConcerns: (state, action) => {
      state.selectedConcerns = {
        ...state.selectedConcerns,
        ...action.payload,
      };
    },
    setRecommendations: (state, action) => {
      state.recommendations = action.payload;
    },
    setServiceCode: (state, action) => {
      state.actual_service_code = [action.payload];
    },
    setProductCode: (state, action) => {
      state.actual_product_code = [action.payload];
    },
  },
});

export const {
  addConsultation,
  clearConsultation,
  addBeforeImage,
  addAfterImage,
  setConcerns,
  setRecommendations,
  setServiceCode,
  setProductCode,
} = consultationSlice.actions;

export default consultationSlice.reducer;
