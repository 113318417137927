import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentStep: 0,
  salonInfo: {
    salonLeapCode: "",
    salonName: "",
    salonLocation: "",
  },
  personalInfoDetails: {
    mobileNumber: "",
    name: "",
    email: "",
  },
};

export const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
    setSalonInfo: (state, action) => {
      state.salonInfo = action.payload;
    },
    setSalonDetails: (state, action) => {
      state.salonInfo.salonName = action.payload.name;
      state.salonInfo.salonLocation = `${action.payload.location}, ${action.payload.city}`;
    },
    clearSalonDetails: (state) => {
      state.salonInfo.salonName = "";
      state.salonInfo.salonLocation = "";
    },
    setPersonalInfo: (state, action) => {
      state.personalInfoDetails = action.payload;
    },
    resetRegistration: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const {
  resetRegistration,
  setCurrentStep,
  setSalonInfo,
  setSalonDetails,
  clearSalonDetails,
  setPersonalInfo,
} = registrationSlice.actions;

export default registrationSlice.reducer;
