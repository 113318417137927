import { configureStore } from "@reduxjs/toolkit";
import globalReducer from "./slices/globalSlice";
import registrationReducer from "./slices/registrationSlice";
import consultationReducer from "./slices/consultationSlice";
import { api } from "./slices/apiSlice";

const reducer = {
  global: globalReducer,
  register: registrationReducer,
  consultation: consultationReducer,
  [api.reducerPath]: api.reducer,
};
const setupStore = (preloadedState) =>
  configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(api.middleware),
    preloadedState,
  });

export default setupStore;
