import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import { ROUTES } from "../../routes/routeConstant";

const ProtectedRoute = ({ isLoggedIn, children }) => {
  if (!isLoggedIn) {
    return <Navigate to={ROUTES.LOGIN} replace />;
  }

  return children;
};

ProtectedRoute.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
};

export default ProtectedRoute;
