import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import DB from "./db";
import { OnlineStatusProvider } from "./hooks/useOnlineStatus";

import AppRouter from "./routes/AppRouter";
import setupStore from "./redux/store";
import "./App.scss";
// import "./i18n";

const store = setupStore();

function App() {
  DB.open();

  return (
    <div className="App transparent-scrollbar">
      <OnlineStatusProvider>
        <Provider store={store}>
          <BrowserRouter>
            <AppRouter />
          </BrowserRouter>
        </Provider>
      </OnlineStatusProvider>
    </div>
  );
}

export default App;
