import { ReactComponent as Spinner } from "../../assets/images/loader.svg";

import "./styles.scss";

export default function Loader() {
  return (
    <div data-testid="loader" className="loader-wrapper">
      <Spinner className={"loader"} />
    </div>
  );
}
