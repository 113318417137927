import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { API_BASE_URL, API_ENDPOINTS } from "../../constants/apiEndPoints";
import DB from "../../db";
import { removeUser } from "../../redux/slices/globalSlice";

const baseQueryToasts = (baseUrl) => {
  const baseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      headers.set("brand_code", `BLG`);
      return headers;
    },
  });
  return async (args, api, extraOptions) => {
    const { error, data } = await baseQuery(args, api, extraOptions);
    if (error) {
      console.log(error.data?.message || error.status || "unknown error");
      if (error.status === 401) {
        await baseQuery(
          {
            method: "POST",
            url: `${API_ENDPOINTS.LOGOUT}`,
          },
          api
        );

        api.dispatch(removeUser());
        await DB.hdDetails.clear();
      }

      return { error: { status: error.status, data: error.data } };
    }
    // if (data?.message) {
    //   alert(data.message);
    //   delete data.message;
    // }
    return { data };
  };
};
export const api = createApi({
  baseQuery: baseQueryToasts(API_BASE_URL),
  // baseQuery: fetchBaseQuery({
  //   baseUrl: API_BASE_URL,
  //   credentials: "same-origin",
  //   prepareHeaders: (headers, { getState }) => {
  //     headers.set("brand_code", `BLG`);
  //     // const token = getState()?.global?.user?.token;
  //     // if (token) {
  //     // headers.set("Authorization", `Bearer ${token}`);
  //     // headers.set("BRAND_CODE", `C3`);
  //     // }
  //     return headers;
  //   },
  //   // responseHandler: (response) => {
  //   //   console.log(response, "...<,");
  //   // },
  //   // validateStatus: (response, body) => {
  //   //   console.log(response, "...>,", body);
  //   // },
  // }),
  reducerPath: "api",
  endpoints: (build) => ({
    genOtp: build.mutation({
      query: ({ source, ...payload }) => ({
        url: `${API_ENDPOINTS.GEN_OTP}?source=${source}`,
        method: "POST",
        body: payload,
      }),
    }),
    verifyOTP: build.mutation({
      query: (payload) => ({
        url: `${API_ENDPOINTS.VERIFY_OTP}`,
        method: "POST",
        body: payload,
      }),
    }),
    login: build.mutation({
      query: (payload) => ({
        url: `${API_ENDPOINTS.LOGIN}`,
        method: "POST",
        body: payload,
      }),
    }),
    logout: build.mutation({
      query: () => ({
        url: `${API_ENDPOINTS.LOGOUT}`,
        method: "POST",
      }),
    }),
    searchSalon: build.query({
      query: (salonLeapCode) =>
        `${API_ENDPOINTS.SEARCH_SALON}/${salonLeapCode}`,
      transformResponse: (response) => response,
    }),
    registerHd: build.mutation({
      query: (payload) => ({
        url: `${API_ENDPOINTS.REGISTER_HD}`,
        method: "POST",
        body: payload,
      }),
    }),
    getRecentConsultation: build.query({
      query: () => `${API_ENDPOINTS.CONSULTATION}`,
      transformResponse: (response) => response,
    }),
    setConsultation: build.mutation({
      query: (payload) => ({
        url: `${API_ENDPOINTS.CONSULTATION}`,
        method: "POST",
        body: payload,
      }),
    }),
    getConcerns: build.query({
      query: () => `${API_ENDPOINTS.CONCERNS}`,
      transformResponse: (response) => response,
    }),
    getConsultation: build.query({
      query: (consultationId) =>
        `${API_ENDPOINTS.CONSULTATION}/${consultationId}`,
      transformResponse: (response) => response,
    }),
    updateHdDetails: build.mutation({
      query: (payload) => ({
        url: `${API_ENDPOINTS.HD_UPDATE}`,
        method: "PUT",
        body: payload,
      }),
    }),
    deactivateHD: build.mutation({
      query: () => ({
        url: `${API_ENDPOINTS.DEACTIVATE_HD}`,
        method: "PUT",
      }),
    }),
    getRecommendation: build.mutation({
      query: (payload) => ({
        url: `${API_ENDPOINTS.RECOMMENDATIONS}`,
        method: "POST",
        body: payload,
      }),
    }),
    updateConsultation: build.mutation({
      query: ({ id, payload }) => ({
        url: `${API_ENDPOINTS.UPDATE_CONSULTATION}/${id}`,
        method: "PUT",
        body: payload,
      }),
    }),
    inactivateConsultations: build.mutation({
      query: (payload) => ({
        url: `${API_ENDPOINTS.INACTIVATE_CONSULTATION}`,
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const {
  useLazyGetConcernsQuery,
  useLazyGetConsultationQuery,
  useGenOtpMutation,
  useVerifyOTPMutation,
  useLoginMutation,
  useLogoutMutation,
  useLazySearchSalonQuery,
  useRegisterHdMutation,
  useSetConsultationMutation,
  useUpdateHdDetailsMutation,
  useGetRecommendationMutation,
  useUpdateConsultationMutation,
  useLazyGetRecentConsultationQuery,
  useDeactivateHDMutation,
  useInactivateConsultationsMutation,
} = api;
