import { createSlice } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";

// eslint-disable-next-line import/no-extraneous-dependencies
// import jwtDecode from 'jwt-decode';

// eslint-disable-next-line no-undef
// const token = localStorage.getItem("token");
let user;
try {
  //   user = { ...jwtDecode(token), token };
} catch (e) {
  user = {};
}
const cookies = new Cookies();
const initialState = {
  isLoggedIn: cookies.get("is-logged-in") ? true : false,
  user,
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload.isLoggedIn;

      if (!cookies.get("is-logged-in")) {
        cookies.set("is-logged-in", true, {
          path: "/",
          expires: new Date(action.payload.expiresIn),
          secure: true,
          sameSite: "Strict",
        });
      }
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    removeUser: (state) => {
      cookies.remove("is-logged-in");
      state.user = initialState;
      state.isLoggedIn = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setIsLoggedIn, setUser, removeUser } = globalSlice.actions;

export default globalSlice.reducer;
