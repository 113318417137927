import { lazy } from "react";

const ErrorScreen = lazy(() => import("../pages/ErrorPage"));
const HomePage = lazy(() => import("../pages/Home"));
const ProfilePage = lazy(() => import("../pages/Profile"));
const ConsultationPage = lazy(() => import("../pages/Consultation"));
const TreatmentPage = lazy(() => import("../pages/Treatment"));
const Login = lazy(() => import("../pages/Login"));
const Registration = lazy(() => import("../pages/Registration"));
const Certificate = lazy(() => import("../pages/Certificate"))

export const ROUTES = {
  REGISTRATION: "/register",
  LOGIN: "/",
  HOME: "/home",
  PROFILE: "/profile",
  CONSULTATION: "/consultation",
  TREATMENT: "/treatment/:id",
  CERTIFICATE: "/certificate",
  OTHER: "*",
};
export const ROUTE_CONSTANTS = [
  {
    pathName: ROUTES.LOGIN,
    component: <Login />,
    isProtected: false,
    errorElement: <ErrorScreen />,
  },
  {
    pathName: ROUTES.CERTIFICATE,
    component: <Certificate name="Dummy Dummy" salon="Salon Details" />,
    isProtected: false,
    errorElement: <ErrorScreen />,
  },
  {
    pathName: ROUTES.REGISTRATION,
    component: <Registration />,
    isProtected: false,
    errorElement: <ErrorScreen />,
  },
  {
    pathName: ROUTES.HOME,
    component: <HomePage />,
    isProtected: true,
    errorElement: <ErrorScreen />,
  },
  {
    pathName: ROUTES.PROFILE,
    component: <ProfilePage />,
    isProtected: true,
    errorElement: <ErrorScreen />,
  },
  {
    pathName: ROUTES.CONSULTATION,
    component: <ConsultationPage />,
    isProtected: true,
    errorElement: <ErrorScreen />,
  },
  {
    pathName: ROUTES.TREATMENT,
    component: <TreatmentPage />,
    isProtected: true,
    errorElement: <ErrorScreen />,
  },
  {
    pathName: ROUTES.OTHER,
    component: <ErrorScreen />,
    isProtected: false,
  },
];

export default ROUTE_CONSTANTS;
