import { Fragment, Suspense, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLiveQuery } from "dexie-react-hooks";

import Loader from "../components/Loader";
import Offline from "../components/Offline";
import ProtectedRoute from "../components/ProtectedRoute/ProtectedRoute";
import DB from "../db";
import { useOnlineStatus } from "../hooks/useOnlineStatus";
import { setUser } from "../redux/slices/globalSlice";
import { ROUTE_CONSTANTS } from "./routeConstant";
import Cookies from "universal-cookie";

const AppRouter = () => {
  const dispatch = useDispatch();
  const isOnline = useOnlineStatus();
  const { isLoggedIn } = useSelector((state) => state.global);
  const hdDetails = useLiveQuery(() => DB.hdDetails.toArray(), []);

  useEffect(() => {
    if (hdDetails?.length) {
      const cookies = new Cookies();
      if (!cookies.get("is-logged-in")) {
        DB.hdDetails.clear();
      }
      dispatch(setUser(hdDetails[0]));
    }
  }, [hdDetails, dispatch]);

  return (
    <>
      <Routes>
        {ROUTE_CONSTANTS.map((route) => (
          <Fragment key={route.pathName}>
            <Route
              index
              // exact={route?.isExact}
              path={route.pathName}
              errorElement={route.ErrorScreen}
              element={
                route.isProtected ? (
                  <ProtectedRoute isLoggedIn={isLoggedIn}>
                    <Suspense fallback={<Loader />}>{route.component}</Suspense>
                  </ProtectedRoute>
                ) : (
                  <Suspense fallback={<Loader />}>{route.component}</Suspense>
                )
              }
            />
          </Fragment>
        ))}
      </Routes>
      {!isOnline ? <Offline /> : null}
    </>
  );
};

export default AppRouter;
